<template>
  <div class="RulesConfig">
    <el-row style="margin-bottom: 20px">
      <el-button type="primary" class="iconfont icon-dcicon_save1" @click="saveRules()">保存</el-button>
      <el-button class="iconfont icon-dcx" @click="$router.go(-1)">取消</el-button>
    </el-row>
    <!-- title -->
    <el-form ref="form" :model="form" label-width="140px">
      <baseSection name="基本信息">
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item label="报警内容:" prop="name" :rules="[{ required: true, message: '请输入报警内容', trigger: 'blur' }]">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item label="报警编号:" prop="code" :rules="[{ required: true, message: '请输入报警编号', trigger: 'blur' }]">
              <el-input v-model="form.code" placeholder="请输入报警编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item label="描述备注:" prop="name">
              <el-input v-model="form.remark" placeholder="请输入描述备注"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="0" :span="7">
            <el-form-item label="启用状态:" prop="status" :rules="[{ required: true, message: '请选择启用状态', trigger: 'blur' }]">
              <el-radio v-model="form.status" :label="0">停用</el-radio>
              <el-radio v-model="form.status" :label="1">启用</el-radio>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item label="报警规则:" prop="isEasy" :rules="[{ required: true, message: '请选择报警规则复杂程度', trigger: 'blur' }]">
              <el-radio-group v-model="form.isEasy" @change="clearSelect">
                <el-radio :label="1">简单</el-radio>
                <el-radio :label="0">复杂</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
      <baseSection name="设置报警参数">
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item label="参数选择:" prop="name" :rules="[{ required: true, message: '请选择参数', trigger: 'blur' }]">
              <el-select v-model="value" multiple placeholder="请选择" v-if="!form.isEasy">
                <el-option v-for="item in paramsList" :key="item.paramId" :label="item.paramName" :value="item.paramId"> </el-option>
              </el-select>
              <el-select v-model="value[0]" placeholder="请选择" v-else>
                <el-option v-for="item in paramsList" :key="item.paramId" :label="item.paramName" :value="item.paramId"> </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
      <baseSection name="设置报警规则">
        <SuperModel ref="flow" :ruleList="ruleList" :nodelist="nodeList" :linklist="linkList" :isEasy="form.isEasy" />
      </baseSection>
      <baseSection name="设置报警通知">
        <el-row>
          <el-col :offset="0" :span="7">
            <el-form-item label="通知邮箱:">
              <el-input v-model="form.emails[0].email" placeholder="请输入通知邮箱"></el-input>
            </el-form-item>
          </el-col>
          <el-col :offset="1" :span="7">
            <el-form-item label="邮箱内容:">
              <el-input v-model="form.emails[0].content" placeholder="请输入邮箱内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </baseSection>
    </el-form>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Base64 from '../base64.js';
import SuperModel from './superModel.vue';
export default {
  name: 'RulesConfig',
  data() {
    return {
      form: {
        deviceId: this.deviceId,
        code: '',
        status: 0,
        name: '',
        expression: '',
        keys: '',
        isEasy: 1,
        remark: '',
        alarmParams: this.ruleList,
        emails: [
          {
            email: '',
            content: ''
          }
        ]
      },
      value: [],
      paramsList: [],
      deviceId: '',
      ruleList: [],
      nodeList: [],
      linkList: [],
      isSave: true
    };
  },
  components: {
    SuperModel,
    baseSection
  },
  watch: {
    value() {
      this.ruleList = [];
      this.value.forEach(item => {
        this.paramsList.forEach(ele => {
          if (item === ele.paramId) {
            this.ruleList.push(ele);
          }
        });
      });
    },
    deep: true
  },
  async created() {
    if (typeof this.$route.query.data === 'object') {
      this.deviceId = this.$route.query.data.deviceId || this.$route.query.data;
      const paramsres = await this.$apis.AlarmConfig.getAlarmParam({ deviceId: this.deviceId });
      this.paramsList = paramsres;
      const res = await this.$apis.AlarmConfig.get({ alarmId: this.$route.query.data.alarmId });
      this.form = res;
      if (!this.form.emails) {
        this.form.emails = [
          {
            email: '',
            content: ''
          }
        ];
      }
      res.alarmParams.forEach(item => {
        // parseInt(item.paramId);
        this.value.push(`${item.paramId}`);
      });
      this.ruleList = res.alarmParams;
      this.nodeList = JSON.parse(Base64.decode(res.flowObject)).nodeList;
      this.linkList = JSON.parse(Base64.decode(res.flowObject)).linkList;
      this.isSave = false;
    } else {
      if (this.$route.query.data === '[object Object]') {
        this.$router.go(-1);
        return false;
      }
      this.isSave = true;
      this.deviceId = this.$route.query.data;
      this.init();
    }
  },
  methods: {
    async init() {
      const res = await this.$apis.AlarmConfig.getAlarmParam({ deviceId: this.deviceId });
      this.paramsList = res;
    },
    clearSelect() {
      // console.log(22112);
      this.value = [];
    },
    async saveRules() {
      if (this.form.name === '') {
        this.$message.error('报警名必填');
        return false;
      }
      if (this.form.code === '') {
        this.$message.error('报警编号必填');
        return false;
      }
      const { nodeList, linkList } = this.$refs.flow.getdata();
      if (nodeList.length === 0) {
        this.$message.error('无法保存,未填写不完整');
        return false;
      }
      const flowObject = this.$refs.flow.getdata();
      if (nodeList.length > 1 && linkList.length === 0) {
        this.$message.error('报警规则缺少与逻辑运算的连线');
        return false;
      }
      const findById = id => {
        const arr = nodeList.filter(item => item.id === id);
        return !!arr && arr.length === 1 ? arr[0] : undefined;
      };

      const resolveFormula = item => {
        const label = item.meta.label;
        const symbal = label === 1 ? '&&' : '||';
        if (item.children) {
          let formula = '';
          for (let i = 0; i < item.children.length; i++) {
            if (i !== item.children.length - 1) {
              formula = `${formula}(${resolveFormula(item.children[i])})${symbal}`;
            } else {
              formula = `${formula}(${resolveFormula(item.children[i])})`;
            }
          }
          return formula;
        }
        return label === 3 ? item.meta.logStr : '__error__';
      };

      linkList.forEach(item => {
        const startItem = findById(item.startId);
        const endItem = findById(item.endId);
        if (!!startItem && !!endItem) {
          if (!startItem.children) {
            startItem.children = [];
          }
          endItem.disabled = false;
          startItem.children.push(endItem);
        }
      });

      const result = nodeList.filter(item => item.disabled !== false);
      if (result.length !== 1) {
        console.error('不合法的计算结果');
        return false;
      }
      this.form.expression = window.btoa(resolveFormula(result[0]));
      this.ruleList.forEach(item => {
        this.form.keys = `${this.form.keys}` + `,${item.tag}`;
      });
      const form = { ...this.form };
      form.deviceId = this.deviceId;
      this.ruleList = [];
      this.value.forEach(item => {
        this.paramsList.forEach(ele => {
          if (item === ele.paramId) {
            this.ruleList.push(ele);
          }
        });
      });
      form.alarmParams = this.ruleList;
      form.flowObject = Base64.encode(JSON.stringify(flowObject));
      console.log('JSON.stringify(this.$route.query)', JSON.stringify(this.$route.query));
      const tenantId = localStorage.getItem('schoolTenantId');
      const userId = localStorage.getItem('schoolUserId');
      if (this.isSave) {
        const { fail } = await this.$apis.AlarmConfig.save({
          ...form,
          tenantId,
          tenantCode: '',
          createdId: userId,
          createdTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        });
        if (fail) {
          return false;
        }
      } else {
        const { fail } = await this.$apis.AlarmConfig.update({
          ...form,
          tenantId,
          tenantCode: '',
          updatedId: Number(userId),
          updatedTime: this.$moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
        });
        if (fail) {
          return false;
        }
      }

      this.$router.go(-1);
    }
  }
};
</script>
<style lang="scss">
.deviceCortrol {
  margin-top: 20px;
}

.el-icon-warning:before {
  font-size: 18px;
}
.hint_text {
  color: #000;
  font-size: 14px;
  margin: 0 10px;
}
</style>

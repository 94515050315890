<template>
  <div class="Rules">
    <div class="super-flow-demo1">
      <div class="node-container">
        <span class="node-item" :key="item.label" v-for="item in nodeItemList" @mousedown="evt => nodeItemMouseDown(evt, item.value)">
          {{ item.label }}
        </span>
      </div>
      <div class="flow-container" ref="flowContainer">
        <super-flow ref="superFlow" :node-list="Nodelist" :link-list="Linklist" :node-menu="nodeMenu" :link-menu="linkMenu" :link-base-style="linkBaseStyle" :link-style="linkStyle">
          <template v-slot:node="{ meta }">
            <div @mouseup="nodeMouseUp" @dblclick="nodeClick(meta)" class="flow-node ellipsis">
              {{ meta.name }}
            </div>
          </template>
        </super-flow>
      </div>
    </div>
    <CalcTemplate :isEasy="isEasy" @getarith="getarith" :dialogVisible="dialogVisible" :paramsList="RuleList" :arr="arr" :str="str" @close="dialogVisible = false" />
  </div>
</template>

<script>
import SuperFlow from 'vue-super-flow';
import CalcTemplate from './template.vue';
import 'vue-super-flow/lib/index.css';
const drawerType = {
  node: 0,
  link: 1
};
export default {
  props: {
    ruleList: {
      type: Array
    },
    nodelist: {
      type: Array
    },
    linklist: {
      type: Array
    },
    isEasy: {
      type: Number
    }
  },
  watch: {
    ruleList() {
      this.RuleList = JSON.parse(JSON.stringify(this.ruleList));
    },
    nodelist() {
      this.Nodelist = JSON.parse(JSON.stringify(this.nodelist));
    },
    linklist() {
      this.Linklist = JSON.parse(JSON.stringify(this.$props.linklist));
    },
    isEasy() {
      if (this.isEasy) {
        this.nodeItemList = [
          {
            label: '运算表达式',
            value: () => ({
              width: 120,
              height: 40,
              meta: {
                label: 3,
                name: '运算表达式'
              }
            })
          }
        ];
        this.Linklist = [];
        this.Nodelist = [];
      } else {
        this.nodeItemList = [
          {
            label: 'and',
            value: () => ({
              width: 120,
              height: 40,
              meta: {
                label: 1,
                name: 'and'
              }
            })
          },
          {
            label: 'or',
            value: () => ({
              width: 120,
              height: 40,
              meta: {
                label: 2,
                name: 'or'
              }
            })
          },
          {
            label: '运算表达式',
            value: () => ({
              width: 120,
              height: 40,
              meta: {
                label: 3,
                name: '运算表达式'
              }
            })
          }
        ];
      }
    }
  },
  components: { SuperFlow, CalcTemplate },
  data() {
    return {
      drawerType,
      nodeItemList: [
        {
          label: '运算表达式',
          value: () => ({
            width: 120,
            height: 40,
            meta: {
              label: 3,
              name: '运算表达式'
            }
          })
        }
      ],
      nodeMenu: [
        [
          {
            label: '删除',
            selected: node => {
              node.remove();
            }
          }
        ]
      ],
      linkMenu: [
        [
          {
            label: '删除',
            selected: link => {
              link.remove();
            }
          }
        ]
      ],
      linkBaseStyle: {
        color: '#666666', // line 颜色
        hover: '#FF0000', // line hover 的颜色
        textColor: '#666666', // line 描述文字颜色
        textHover: '#FF0000', // line 描述文字 hover 颜色
        font: '14px Arial', // line 描述文字 字体设置 参考 canvas font
        dotted: false, // 是否是虚线
        lineDash: [4, 4], // 虚线时生效
        background: 'rgba(255,255,255,0.6)' // 描述文字背景色
      },
      dragConf: {
        isDown: false,
        isMove: false,
        offsetTop: 0,
        offsetLeft: 0,
        clientX: 0,
        clientY: 0,
        ele: null,
        info: null
      },
      linkSetting: {
        desc: ''
      },
      nodeSetting: {
        name: '',
        desc: ''
      },
      meta: '',
      dialogVisible: false,
      // 传递数据
      str: '',
      arr: [],
      RuleList: [],
      Linklist: [],
      Nodelist: [],
      // 自用数据
      datalist: [],
      deviceId: this.$parent.deviceId
    };
  },
  created() {
    this.RuleList = JSON.parse(JSON.stringify(this.ruleList));
    this.Nodelist = JSON.parse(JSON.stringify(this.nodelist));
    this.Linklist = JSON.parse(JSON.stringify(this.$props.linklist));
  },
  mounted() {
    document.addEventListener('mousemove', this.docMousemove);
    document.addEventListener('mouseup', this.docMouseup);
    this.$once('hook:beforeDestroy', () => {
      document.removeEventListener('mousemove', this.docMousemove);
      document.removeEventListener('mouseup', this.docMouseup);
    });
  },
  methods: {
    getarith(Arr, str) {
      let arr = Arr;
      const { nodeList } = this.$refs.superFlow.toJSON();
      let logStr = '';
      Arr.forEach(item => {
        if (item.Type === 0) {
          logStr = logStr + item.name;
        } else {
          logStr = `${logStr}paramId_${item.id}`;
        }
      });
      nodeList.forEach(item => {
        if (item.meta.index === this.meta) {
          item.meta.name = str;
          item.meta.arr = arr;
          item.meta.str = str;
          item.meta.logStr = logStr;
        }
      });
    },
    getdata() {
      return this.$refs.superFlow.toJSON();
    },
    nodeMouseUp(evt) {
      evt.preventDefault();
    },
    async nodeClick(meta) {
      if (meta.label === 3 && this.RuleList.length !== 0) {
        this.dialogVisible = true;
        if (meta.arr) {
          this.arr = meta.arr;
          this.str = meta.str;
        }
      }
      this.meta = meta.index;
    },
    linkStyle(link) {
      if (link.meta && link.meta.desc === '1') {
        return {
          color: 'red',
          hover: '#FF00FF',
          dotted: true
        };
      } else {
        return {};
      }
    },
    docMousemove({ clientX, clientY }) {
      const conf = this.dragConf;

      if (conf.isMove) {
        conf.ele.style.top = `${clientY - conf.offsetTop}px`;
        conf.ele.style.left = `${clientX - conf.offsetLeft}px`;
      } else if (conf.isDown) {
        // 鼠标移动量大于 5 时 移动状态生效
        conf.isMove = Math.abs(clientX - conf.clientX) > 5 || Math.abs(clientY - conf.clientY) > 5;
      }
    },
    docMouseup({ clientX, clientY }) {
      const conf = this.dragConf;
      conf.isDown = false;

      if (conf.isMove) {
        const { top, right, bottom, left } = this.$refs.flowContainer.getBoundingClientRect();

        // 判断鼠标是否进入 flow container
        if (clientX > left && clientX < right && clientY > top && clientY < bottom) {
          // 获取拖动元素左上角相对 super flow 区域原点坐标
          const coordinate = this.$refs.superFlow.getMouseCoordinate(clientX - conf.offsetLeft, clientY - conf.offsetTop);

          // 添加节点

          this.$refs.superFlow.addNode({
            coordinate,
            ...conf.info
          });
        }

        conf.isMove = false;
      }

      if (conf.ele) {
        conf.ele.remove();
        conf.ele = null;
      }
    },
    nodeItemMouseDown(evt, infoFun) {
      const { clientX, clientY, currentTarget } = evt;

      const { top, left } = evt.currentTarget.getBoundingClientRect();

      const conf = this.dragConf;
      const ele = currentTarget.cloneNode(true);
      Object.assign(this.dragConf, {
        offsetLeft: clientX - left,
        offsetTop: clientY - top,
        clientX,
        clientY,
        info: {
          width: 120,
          height: 40,
          meta: {
            ...infoFun().meta,
            index: new Date().getTime()
          }
        },
        ele,
        isDown: true
      });
      this.i += 1;
      if (this.i > 10) {
        this.i = 0;
      }
      ele.style.position = 'fixed';
      ele.style.margin = '0';
      ele.style.top = `${clientY - conf.offsetTop}px`;
      ele.style.left = `${clientX - conf.offsetLeft}px`;
      this.$el.appendChild(this.dragConf.ele);
    }
  }
};
</script>

<style scoped lang="scss">
.Rules {
  height: 300px;
}
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
}
.link-base-style-form {
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdcdc;
}
.link-base-style-form .el-form-item {
  margin-bottom: 12px;
}
.super-flow-demo1 {
  margin-top: 20px;
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
}
.super-flow-demo1 > .node-container {
  width: 200px;
  float: left;
  height: 100%;
  text-align: center;
  background-color: #ffffff;
}
.super-flow-demo1 > .flow-container {
  width: calc(100% - 200px);
  float: left;
  height: 100%;
  overflow: hidden;
}
.super-flow-demo1 .super-flow__node .flow-node {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  line-height: 40px;
  padding: 0 6px;
  font-size: 12px;
}
.node-item {
  font-size: 14px;
  display: inline-block;
  height: 30px;
  width: 120px;
  margin-top: 20px;
  background-color: #ffffff;
  line-height: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
  text-align: center;
  z-index: 6;
}
.node-item:hover {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.4);
}
</style>
